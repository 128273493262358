// @flow
export class Deferred<T = mixed> {
  promise: Promise<T>;
  reject: (?Error) => void;
  resolve: (T) => void;
  fullfilled: boolean = false;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = (...args) => {
        resolve(...args);
        this.fullfilled = true;
      };
    });
  }
}

export default Deferred;
