// @flow

import type { OrderedFrame } from '../modules/viewer/imageloaders/BaseImageLoader';
import type { SupportedTextureTypes } from './textureUtils';

const DEFAULT_ARR_ALLOCATE = 16;
const DEFAULT_ENTRIES = 2 ** 16;

const getEntryCount = (descriptorEntries: ?number): number => {
  // Explicit check for 0 that should become the DEFAULT_ENTRIES
  if (descriptorEntries == null || descriptorEntries === 0) {
    return DEFAULT_ENTRIES;
  }

  return descriptorEntries;
};

const getArrayType = (type: string) => {
  switch (type) {
    case 'uint8':
      return Uint8Array;
    case 'uint16':
      return Uint16Array;
    default:
      return null;
  }
};

export const processModalityLut = (
  frame: ?OrderedFrame,
  pixels: SupportedTextureTypes
): SupportedTextureTypes => {
  if (frame?.modules.modalityLut == null) {
    throw new Error('Unable to apply modality lut to raw frame because the module is missing');
  }
  const modalityLut = frame.modules.modalityLut;
  if (modalityLut?.modalityLutSequence != null && modalityLut?.modalityLutSequence.length > 0) {
    const { lutDescriptor, lutData: uncData } = modalityLut.modalityLutSequence[0] ?? {};
    const numEntries = getEntryCount(lutDescriptor[0]);
    const firstMap = lutDescriptor[1];
    const depth = lutDescriptor[2] ?? DEFAULT_ARR_ALLOCATE;

    const arrType = `uint${depth}`;

    // TODO: handle endianess (potential backend manipulation)

    const arrConstructor = getArrayType(arrType);
    if (arrConstructor == null) {
      throw new Error('Unsupported Array Type');
    }
    const lutData = new arrConstructor(uncData);

    // Maps pixels with for loop
    const result = new arrConstructor(pixels.length);

    for (let i = 0; i < pixels.length; i++) {
      const value = pixels[i];
      if (value >= firstMap) {
        const mappedIndex = value - firstMap;
        if (lutData[mappedIndex] == null) {
          throw new Error(`Out of bounds of the LUT Data: ${mappedIndex}`);
        }
        const lutIndex = Math.min(Math.max(mappedIndex, 0), numEntries - 1);
        result[i] = lutData[lutIndex];
      } else {
        // Else, it remains 0, mapping to the first LUT entry
        result[i] = lutData[0];
      }
    }

    return result;
  } else if (modalityLut?.rescaleSlope != null && modalityLut?.rescaleIntercept != null) {
    const rescaleSlope = parseFloat(modalityLut.rescaleSlope);
    const rescaleIntercept = parseFloat(modalityLut.rescaleIntercept);

    let maxValue = Number.NEGATIVE_INFINITY;
    let minValue = Number.POSITIVE_INFINITY;
    let isFloat = false;
    const result = new Float64Array(pixels.length);

    for (let i = 0; i < pixels.length; i++) {
      result[i] = pixels[i] * rescaleSlope + rescaleIntercept;

      if (!Number.isInteger(result[i])) {
        isFloat = true;
      }

      if (maxValue < result[i]) {
        maxValue = result[i];
      }

      if (minValue > result[i]) {
        minValue = result[i];
      }
    }

    if (!isFloat) {
      if (minValue >= 0 && maxValue <= 255) {
        return Uint8Array.from(result);
      }
      if (minValue >= -128 && maxValue <= 127) {
        return Int8Array.from(result);
      }
      if (minValue >= 0 && maxValue <= 65535) {
        return Uint16Array.from(result);
      }
      if (minValue >= -32768 && maxValue <= 32767) {
        return Int16Array.from(result);
      }
    }

    if (minValue >= -2147483648 && maxValue <= 2147483647) {
      return Float32Array.from(result);
    }

    return result;
  }

  return pixels;
};
