// @flow

import type { SupportedTextureTypes, SupportedTexturesMap } from './textureUtils';

// These are ordered by the size they can hold
export const TypedArrayShortName: {
  Uint8Array: 'u1',
  RawUint8Array: 'U1',
  Int8Array: 'i1',
  RawInt8Array: 'I1',
  Uint16Array: 'u2',
  RawUint16Array: 'U2',
  Int16Array: 'i2',
  RawInt16Array: 'I2',
  Uint32Array: 'u4',
  RawUint32Array: 'U4',
  Int32Array: 'i4',
  RawInt32Array: 'I4',
  Float32Array: 'f4',
  RawFloat32Array: 'F4',
  Float64Array: 'f8',
  RawFloat64Array: 'F8',
} = {
  Uint8Array: 'u1',
  RawUint8Array: 'U1',
  Int8Array: 'i1',
  RawInt8Array: 'I1',
  Uint16Array: 'u2',
  RawUint16Array: 'U2',
  Int16Array: 'i2',
  RawInt16Array: 'I2',
  Uint32Array: 'u4',
  RawUint32Array: 'U4',
  Int32Array: 'i4',
  RawInt32Array: 'I4',
  Float32Array: 'f4',
  RawFloat32Array: 'F4',
  Float64Array: 'f8',
  RawFloat64Array: 'F8',
};

export const RAW_ARRAY_TYPES = [
  TypedArrayShortName.RawFloat32Array,
  TypedArrayShortName.RawFloat64Array,
  TypedArrayShortName.RawInt16Array,
  TypedArrayShortName.RawInt32Array,
  TypedArrayShortName.RawInt8Array,
  TypedArrayShortName.RawUint16Array,
  TypedArrayShortName.RawUint32Array,
  TypedArrayShortName.RawUint8Array,
];

export type TypedArrayShortNameType = $Values<typeof TypedArrayShortName>;

export function castToTypedArrayShortName(str: string): TypedArrayShortNameType {
  switch (str) {
    case 'u1':
      return TypedArrayShortName.Uint8Array;
    case 'i1':
      return TypedArrayShortName.Int8Array;
    case 'I1':
      return TypedArrayShortName.RawInt8Array;
    case 'U1':
      return TypedArrayShortName.RawUint8Array;
    case 'u2':
      return TypedArrayShortName.Uint16Array;
    case 'i2':
      return TypedArrayShortName.Int16Array;
    case 'I2':
      return TypedArrayShortName.RawInt16Array;
    case 'U2':
      return TypedArrayShortName.RawUint16Array;
    case 'u4':
      return TypedArrayShortName.Uint32Array;
    case 'i4':
      return TypedArrayShortName.Int32Array;
    case 'U4':
      return TypedArrayShortName.RawUint32Array;
    case 'I4':
      return TypedArrayShortName.RawUint32Array;
    case 'f4':
      return TypedArrayShortName.Float32Array;
    case 'F4':
      return TypedArrayShortName.RawFloat32Array;
    case 'f8':
      return TypedArrayShortName.Float64Array;
    case 'F8':
      return TypedArrayShortName.RawFloat64Array;
    default:
      throw new Error(`Unhandled data type encountered: ${str}`);
  }
}

export function castBufferToSupportedTextureTypedArray(
  kind: TypedArrayShortNameType,
  buffer: ArrayBuffer,
  SUPPORTED_TEXTURES: SupportedTexturesMap
): SupportedTextureTypes {
  switch (kind) {
    // Supported
    case TypedArrayShortName.Uint8Array:
      return new Uint8Array(buffer);

    // Supported if ext_render_snorm is true
    case TypedArrayShortName.Int8Array: {
      const intData = new Int8Array(buffer);
      if (SUPPORTED_TEXTURES.snorm) {
        return intData;
      } else if (SUPPORTED_TEXTURES.norm16) {
        return Int16Array.from(intData);
      } else {
        return Float32Array.from(intData);
      }
    }

    // Supported if ext_texture_norm16 is true
    case TypedArrayShortName.Uint16Array: {
      const uintData = new Uint16Array(buffer);
      if (SUPPORTED_TEXTURES.norm16) {
        return uintData;
      } else {
        return Float32Array.from(uintData);
      }
    }

    // Supported if ext_texture_norm16 is true
    case TypedArrayShortName.Int16Array: {
      const intData = new Int16Array(buffer);
      if (SUPPORTED_TEXTURES.norm16) {
        return intData;
      } else {
        return Float32Array.from(intData);
      }
    }

    // Supported
    case TypedArrayShortName.Float32Array:
      return new Float32Array(buffer);

    // supported without warning temporarily
    case TypedArrayShortName.Uint32Array:
    case TypedArrayShortName.Int32Array:
    case TypedArrayShortName.Float64Array:
      return new Float64Array(buffer);
    default:
      throw new Error(`Unhandled data type encountered: ${kind}`);
  }
}
