// @flow
import { sleep } from 'utils/sleep';

const DEFAULT_RETRIES = 3;

const RETRIED_STATUS_CODES = [502, 504];

export async function fetchWithRetry(
  url: URL,
  fetchOptions: RequestOptions = {},
  tries: number = DEFAULT_RETRIES,
  delay: number = 500
): Promise<Response> {
  let response: ?Response;
  let err: ?Error;

  try {
    response = await fetch(url, fetchOptions);
  } catch (e) {
    err = e;
  }

  // Network error (could be CORS, header issues, network offline, network blip)
  if (response == null) {
    if (tries !== 0) {
      await sleep(delay);

      return fetchWithRetry(url, fetchOptions, tries - 1);
    }
    throw err ?? new Error(`Unable to retry.`);
  }

  if (response.ok) {
    return response;
  }

  if (!RETRIED_STATUS_CODES.includes(response.status)) {
    throw new Error(`Unable to retry with response status ${response.status}`);
  }

  if (tries === 0) {
    throw new Error(`No remaining retries. Final response finished with ${response.status}`);
  }

  await sleep(delay);

  return fetchWithRetry(url, fetchOptions, tries - 1);
}
